import React from "react"
import { Helmet } from "react-helmet"
import moment from "moment"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { HeadingBasic } from "../../components/heading/heading"

import "../../graphql/fragments"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { NavTabs, TabPane } from "../../components/ak-tabs/tabs"
import { Container } from "../../components/grid/grid"
import { CoverMain } from "../../components/cover-main/cover-main"

import { PDFMultipleTemplate } from "../../components/document/document"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

/* ==========================================================================
    Functions
   ========================================================================== */
const groupBy = (array, key) => {
  if (array) {
    return array.reduce((result, currentValue) => {
      ;(result[currentValue["node"]["acfFile"][key]] =
        result[currentValue["node"]["acfFile"][key]] || []).push(currentValue)
      return result
    }, {})
  }
}

// sort by name in descending order
const compareName = (a, b) => {
  const reportA = a.node.title
  const reportB = b.node.title

  let comparison
  if (reportA > reportB) {
    comparison = 1
  } else if (reportA < reportB) {
    comparison = -1
  }

  return comparison
}

const remodelAnnual = (arr, year) => {
  const annual = arr
    .filter((ann) => {
      return ann[0] === year;
    })?.[0]?.[1]
    .sort(compareName);

  return annual?.length > 0 ? 
    annual.map((ann) => {
      return {
        title: ann.node.title || "Title",
        linkTo:
          ann.node.acfFile.file !== null &&
          ann.node.acfFile.file.mediaItemUrl,
        fileSize:
          ann.node.acfFile.file !== null && ann.node.acfFile.file.filesize,
        fileId:
          ann.node.acfFile.file != null && ann.node.acfFile.file.databaseId,
        date: `${moment(ann.node.date).format("DD MMMM YYYY")} • ${moment(
          ann.node.date
        ).format("HH:mm")}`,
      };
    })
  : [];
};

/* ==========================================================================
    Component
   ========================================================================== */
const Reports = ({ annualReports, financialReports, circularReports }) => {
  const annualGroupByYear = annualReports ? groupBy(annualReports, "year") : []
  // split annualGroupByYear to an array
  const annualArr =
    annualGroupByYear ?
      Object.keys(annualGroupByYear).map(key => [
        key,
        annualGroupByYear[key],
      ]) :
    []

  const financialGroupedByYear = financialReports ? groupBy(financialReports, "year") : []
  // split financialGroupedByYear to an array
  const financialArr =
    financialGroupedByYear ?
      Object.keys(financialGroupedByYear).map(key => [
        key,
        financialGroupedByYear[key],
      ]) :
    []

  const circularGroupByYear = circularReports ? groupBy(circularReports, "year") : []
  const circularArr =
    circularGroupByYear ?
      Object.keys(circularGroupByYear).map(key => [
        key,
        circularGroupByYear[key],
      ]) :
    []

  const allYearData = [
    ...annualArr.map((item) => item[0]),
    ...financialArr.map((item) => item[0]),
    ...circularArr.map((item) => item[0]),
  ]
    .filter((x, i, a) => a.indexOf(x) === i) // remove duplicate
    .sort((a, b) => b - a); // sort year in descending order

  const pdfData = allYearData.map((year) => {
    const annual = remodelAnnual(annualArr, year);
    const circular = remodelAnnual(circularArr, year);
    const financial = remodelAnnual(financialArr, year);

    return {
      title: year,
      sections: [
        { title: "Circular", data: circular },
        { title: "Annual Report", data: annual },
        { title: "Financial Report", data: financial },
      ],
      type: "annual",
    };
  });

  if(!pdfData) return null
  return (
    <section className="report">
      {pdfData.map((item, i) => (
        <PDFMultipleTemplate
          key={i}
          title={item.title}
          sections={item.sections}
          type={item.type}
        />
      ))}
    </section>
  )
}

/* ==========================================================================
    Render
   ========================================================================== */

const AnnualReport = location => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerSinarmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 738) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "annual-reports") {
          title
          slug
          blocks {
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreButtonBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ...AcfIconListBlock
            ...CoreGroupBlock
          }
          translation(language: ID) {
            title
            blocks {
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreButtonBlock
              ...CoreCoverBlock
              ...CoreShortcodeBlock
              ...AcfIconListBlock
              ...CoreGroupBlock
            }
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
        annual: fileCategory(id: "annual", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
        financial: fileCategory(id: "financial", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
        circular: fileCategory(id: "circular", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
      }
    }
  `)

  const wordPress =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const seo =
    data?.wordPress?.pageBy?.translation?.seo || data?.wordPress?.pageBy?.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  const annualGroupedByCompany =
    data.wordPress.annual &&
    groupBy(data.wordPress.annual.filesPdf.edges, "company")
  const circularGroupedByCompany =
    data.wordPress.circular &&
    groupBy(data.wordPress.circular.filesPdf.edges, "company")

  return (
    <Layout location={location} currentLocation={location.uri} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={
            data?.wordPress?.pageBy?.translation?.title ||
            data?.wordPress?.pageBy?.title
          }
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="General Information"
          className="cover-md"
        >
          <Breadcrumbs path={location.uri} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "core/paragraph":
            return (
              <section key={`core/paragraph-${i}`} className="py-main">
                <div className="container container-md">
                  <HeadingBasic
                    text={[
                      item.attributes.content ||
                        "The Investor Relations Section is dedicated for shareholders and investors. Here you will find updated annual report from Sinarmas Limited, PT. Bumi Serpong Damai Tbk, and PT. Duta Pertiwi Tbk",
                    ]}
                    className="mb-0"
                  />
                </div>
              </section>
            )
          default:
            return <div key={`empty-block-${i}`}></div>
        }
      })}

      <Container>
        <NavTabs
          scrollable
          className="pt-4"
          data={[
            {
              name: "Sinarmas Land Limited",
              target: "sinarmasland",
            },
            { name: "PT Bumi Serpong Damai Tbk", target: "bsd" },
            {
              name: "PT Duta Pertiwi Tbk",
              target: "dutaPertiwi",
            },
          ]}
        />
      </Container>

      {annualGroupedByCompany && (
        <>
          <TabPane id="sinarmasland" className="py-lg-5 pt-5 pt-lg-0 active">
            <Reports
              annualReports={
                annualGroupedByCompany &&
                annualGroupedByCompany["Sinarmas Land Limited"] !== undefined &&
                annualGroupedByCompany["Sinarmas Land Limited"]
              }
              circularReports={
                circularGroupedByCompany &&
                circularGroupedByCompany["Sinarmas Land Limited"] !== undefined &&
                circularGroupedByCompany["Sinarmas Land Limited"]}
            />
          </TabPane>
          <TabPane id="bsd" className="py-lg-5 pt-5 pt-lg-0">
            <Reports
              annualReports={
                annualGroupedByCompany &&
                annualGroupedByCompany["PT Bumi Serpong Damai Tbk"] !== undefined &&
                annualGroupedByCompany["PT Bumi Serpong Damai Tbk"]
              }
              circularReports={
                circularGroupedByCompany &&
                circularGroupedByCompany["PT Bumi Serpong Damai Tbk"] !== undefined &&
                circularGroupedByCompany["PT Bumi Serpong Damai Tbk"]}
            />
          </TabPane>
          <TabPane id="dutaPertiwi" className="py-lg-5 pt-5 pt-lg-0">
            <Reports
              annualReports={
                annualGroupedByCompany &&
                annualGroupedByCompany["PT Duta Pertiwi Tbk"] !== undefined &&
                annualGroupedByCompany["PT Duta Pertiwi Tbk"]
              }
              circularReports={
                circularGroupedByCompany &&
                circularGroupedByCompany["PT Duta Pertiwi Tbk"] !== undefined &&
                circularGroupedByCompany["PT Duta Pertiwi Tbk"]}
            />
          </TabPane>
        </>
      )}
    </Layout>
  )
}

export default AnnualReport
